<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item label="扣款狀態" prop="status">
        <el-select v-model="formData.status" @change="updateStatus" :disabled="selectItem.status === 'refund'">
          <el-option
            v-for="option in statusOption"
            :key="option.value"
            :value="option.value"
            :label="option.label"
            :disabled="option.disabled"
          />
        </el-select>
        <p v-show="!canAutoRefund" class="text-sub">已超過系統可自動退款期限，不會執行自動退款，請手動退款。</p>
        <!-- <el-button v-if="failRecord" type="text" class="absolute right-0 bottom-full underline" @click="$emit('openRecord')">扣款失敗紀錄</el-button> -->
      </el-form-item>
      <div v-if="formData.status === 'paid'" class="paymentType">
        <el-form-item label="扣款方式" prop="paymentType">
          <el-input v-model="formData.paymentType" placeholder="請輸入" />
        </el-form-item>
        <el-form-item label="扣款金額" prop="paymentAmount">
          <el-input v-model="formData.paymentAmount" placeholder="請輸入" />
          <p class="priceInfo">本次扣款金額：{{ selectItem.paymentAmount }}</p>
        </el-form-item>
      </div>
      <div v-if="formData.status === 'refund'" class="paymentType">
        <el-form-item label="退款方式" prop="refundType">
          <el-input v-model="formData.refundType" placeholder="請輸入" />
        </el-form-item>
        <el-form-item label="退款金額" prop="refundAmount">
          <el-input v-model="formData.refundAmount" placeholder="請輸入" />
          <p class="priceInfo">本次扣款金額：{{ selectItem.refundAmount }}</p>
        </el-form-item>
      </div>
      <div v-if="selectItem.status === 'paid' || selectItem.status === 'refund'" class="origin">
        <el-form-item label="首次扣款來源" prop="origin">
          <p v-if="selectItem.status === 'paid'" class="text-gray-60 text-sub">{{ originConfig(selectItem.status, selectItem.paymentOrigin) }}</p>
          <p v-if="selectItem.status === 'refund'" class="text-gray-60 text-sub">{{ originConfig(selectItem.status, selectItem.refundOrigin) }}</p>
        </el-form-item>
      </div>
      <el-form-item label="扣款日" prop="paymentDate">
        <p v-if="editPaymentDate" class="text-gray-60 text-sub">{{ formData.paymentDate ? formatDate(formData.paymentDate, 'YYYY/MM/DD') : '-' }}</p>
        <el-date-picker
          v-else
          v-model="formData.paymentDate"
          type="date"
          placeholder="選擇日期"
        />
      </el-form-item>
      <el-form-item v-if="formData.status === 'refund'" label="退款日" prop="refundDate">
        <el-date-picker
          v-model="formData.refundDate"
          type="date"
          placeholder="選擇日期"
        />
      </el-form-item>
      <el-form-item label="備註" prop="note">
        <el-input v-model="formData.note" type="textarea" rows="3" placeholder="請輸入" />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <div class>
        <el-button plain @click="$emit('close')">取消</el-button>
        <el-button type="primary" @click="handleConfirm">
          確認
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { pubAptPaymentTypes } from '@/config/pubApt'
import { defineComponent, reactive, computed, ref, onMounted, set } from 'vue'
import { get, filter, map } from 'lodash'
import { noEmptyRules } from '@/validation'
import { UpdateSubscribeOrderPayment, GetValidateAutoRefund } from '@/api/subscribe'
import { useShop } from '@/use/shop'
import { subscribeOrderPaymentStatusConfig, subscribeOrderPaymentOriginConfig, subscribeOrderPaymentOriginRefundConfig } from '@/config/subscribe'
import { formatDate } from '@/utils/date'
import { useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'EditSubscribePaymentRecordDialog',
  components: {
  },
  props: {
    selectItem: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'create',
    },
    failRecord: {
      type: Number,
      default: 0,
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const router = useRoute()
    const formData = reactive({
      status: '',
      paymentType: null,
      paymentAmount: null,
      paymentDate: null,
      refundType: null,
      refundAmount: null,
      refundDate: null,
      name: '',
      note: '',
      origin: '',
    })
    const formRef = ref(null)
    const formRules = reactive({
      status: [noEmptyRules()],
      // name: [noEmptyRules()],
      paymentType: [noEmptyRules()],
      paymentAmount: [noEmptyRules()],
      refundType: [noEmptyRules()],
      refundAmount: [noEmptyRules()],
      refundDate: [noEmptyRules()],
    })
    const canAutoRefund = ref(true)
    const dialogTitle = computed(() => {
      let title
      // if (props.type === 'create') title = '新增品項'
      if (props.type === 'edit') title = '編輯扣款紀錄'
      // if (props.type === 'view') title = '查看品項'
      return title
    })
    const handleConfirm = async () => {
      // if (props.type === 'create') await createPubAptPayment()
      if (props.type === 'edit') await updateSubscribeOrderPayment()
      // if (props.type === 'view') emit('close')
    }
    const compactData = computed(() => {
      if (formData.status === 'wait') {
        return {
          status: formData.status,
          note: formData.note || undefined,
        }
      }
      return {
        status: formData.status,
        paymentType: formData.paymentType || undefined,
        paymentAmount: formData.paymentAmount || undefined,
        paymentDate: formData.paymentDate || undefined,
        refundType: formData.refundType || undefined,
        refundAmount: formData.refundAmount || undefined,
        refundDate: formData.refundDate || undefined,
        note: formData.note || undefined,
      }
    })
    const statusOption = computed(() => {
      const status = get(props.selectItem, 'status')
      let list = []
      switch (status) {
      case 'cancel':
        list = ['paid']
        break
      case 'fail':
      case 'wait':
        list = ['cancel', 'paid']
        break
      case 'paid':
        list = ['refund']
        break
      default:
        list = []
      }
      list.push(status) // 加入原本的狀態
      // 原本的狀態加上disabled
      return map(filter(subscribeOrderPaymentStatusConfig, item => list.includes(item.value)), item => {
        return {
          value: item.value,
          label: item.label,
          disabled: item.value === status,
        }
      })
    })
    const editPaymentDate = computed(() => {
      return ['refund', 'cancel'].includes(formData.status) || get(props.selectItem, 'status') === 'paid'
    })
    const updateSubscribeOrderPayment = async () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          console.log(compactData.value)
          const [, err] = await UpdateSubscribeOrderPayment({
            shopId: shopId.value,
            orderId: router.params.id,
            id: formData.id,
            ...compactData.value,
          })
          if (err) return window.$message.error(err)

          window.$message.success('更新成功！')
          emit('refresh')
          emit('close')
        }
      })
    }
    const updateStatus = async (status) => {
      canAutoRefund.value = true
      if (status === 'paid') {
        set(formData, 'paymentDate', new Date())
        await getValidateAutoRefund()
      }
    }
    const getValidateAutoRefund = async () => {
      const [res, err] = await GetValidateAutoRefund({
        shopId: shopId.value,
        orderId: router.params.id,
        id: formData.id,
      })
      if (err) return window.$message.error(err)
      canAutoRefund.value = res.canAutoRefund
    }
    const originConfig = (type, origin) => {
      if (type === 'paid') return get(subscribeOrderPaymentOriginConfig[origin], 'label')
      if (type === 'refund') return get(subscribeOrderPaymentOriginRefundConfig[origin], 'label')
    }
    onMounted(async () => {
      if (props.selectItem) {
        // console.log(props.selectItem)
        Object.assign(formData, props.selectItem)
        formData.paymentDate = get(props.selectItem, 'expectedOrderPaymentDate')
        // console.log('formData', formData)
        // formData.status = null
      }
    })
    return {
      get,
      handleConfirm,
      pubAptPaymentTypes,
      formData,
      formRules,
      dialogTitle,
      formRef,
      statusOption,
      originConfig,
      formatDate,
      updateStatus,
      editPaymentDate,
      subscribeOrderPaymentOriginConfig,
      canAutoRefund,
    }
  },
})
</script>

<style scoped lang="scss">
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  @apply w-full;
}
.paymentType {
  @apply flex gap-[8px];
  .el-form-item {
    @apply w-full;
    .el-input {
      @apply w-full;
    }
  }
}
.priceInfo {
  @apply absolute right-0 bottom-full text-gray-80 text-sm font-medium
}
</style>
