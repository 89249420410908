<template>
  <el-dialog
    title="暫停訂閱"
    :visible="true"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="formRef" label-position="left" :model="formData" :rules="formRules" class="form">
      <el-form-item label="選擇暫停起始日期" prop="startAt">
        <el-select v-model="formData.startAt">
          <el-option
            v-for="option in periodList"
            :key="option.id"
            :value="option.id"
            :label="option.label"
            :disabled="option.disable"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="選擇恢復訂閱日期" prop="endAt">
        <el-select v-model="formData.endAt">
          <el-option
            v-for="option in periodList"
            :key="option.id"
            :value="option.id"
            :label="option.label"
            :disabled="option.disable"
          />
        </el-select>
      </el-form-item>
      <div v-if="formData.startAt && formData.endAt" class="preview w-[560px] mb-[20px]">
        <GrayBlockContainer>
          <div class="flex flex-col gap-[4px] text-gray-100">
            <p class="font-medium">暫停時長：<span class="font-normal"> {{ selected.period }}</span></p>
            <p class="font-medium">下次扣款日：<span class="font-normal">{{ selected.nextPaymentDate }}</span></p>
            <p class="font-medium">最後扣款日：<span class="font-normal">{{ selected.lastPaymentDate }}</span></p>
          </div>
        </GrayBlockContainer>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <div class>
        <el-button plain @click="$emit('close')">取消</el-button>
        <el-button type="primary" @click="onConfirm">
          確認
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import { get, find, filter, map } from 'lodash'
import { noEmptyRules, noBeforeStartDate } from '@/validation'
import { useTable } from '@/use/table'
import { GetSubscribeOrderPayment } from '@/api/subscribe'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'StopSubscribeDialog',
  components: {
    GrayBlockContainer,
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { dateFormat, shopId } = useTable()
    const paymentData = ref([])
    const formRef = ref(null)
    const formData = reactive({
      startAt: null,
      endAt: null,
    })
    const formRules = computed(() => {
      const rules = {
        startAt: [noEmptyRules()],
        endAt: [noEmptyRules(), noBeforeStartDate(formData.startAt)],
      }
      return rules
    })
    const currentPeriod = computed(() => {
      return get(find(paymentData.value, item => dayjs().isBetween(item.startDate, item.endDate, 'day', '[)')), 'periodNumber')
    })
    const periodList = computed(() => {
      const restPeriod = filter(paymentData.value, item => item.periodNumber >= currentPeriod.value)
      return map(restPeriod, (item, index, array) => {
        let label = `${dateFormat(item.endDate, 'YYYY/MM/DD')}`
        if (index === array.length - 1) {
          label += ' (本次訂閱原定終止日期)'
        }
        return {
          label,
          ...item,
          disable: index === array.length - 1,
        }
      })
    })
    const selected = computed(() => {
      const pauseItem = find(paymentData.value, item => item.id === formData.startAt)
      const resumeItem = find(paymentData.value, item => item.id === formData.endAt)
      const period = get(resumeItem, 'periodNumber') - get(pauseItem, 'periodNumber') + 1
      const nextPayment = find(paymentData.value, item => item.periodNumber === get(resumeItem, 'periodNumber') + 1)
      const nextPaymentDate = nextPayment ? dateFormat(nextPayment.endDate, 'YYYY/MM/DD') : '-'
      const lastItem = paymentData.value[get(paymentData, 'value.length') - 1]
      const lastpayment = dayjs(lastItem.endDate).add(period, 'month').format('YYYY/MM/DD')
      const lastPeriod = lastItem.periodNumber + period
      return {
        pauseItem: pauseItem.periodNumber,
        resumeItem: resumeItem.periodNumber,
        period: period ? `${period} 期 (${period} 個月)` : '-', // TODO: p1 monthly only
        nextPaymentDate: nextPaymentDate ? `${nextPaymentDate} (第 ${nextPayment.periodNumber} 期扣款日)` : '-',
        lastPaymentDate: `${lastpayment} (第 ${lastPeriod} 期扣款日)`,
      }
    })
    const pausePeriod = computed(() => {
      const startAt = formData.startAt ? new Date(formData.startAt) : null
      const endAt = formData.endAt ? new Date(formData.endAt) : null
      const months = (startAt && endAt) ? endAt.getMonth() - startAt.getMonth() + (12 * (endAt.getFullYear() - startAt.getFullYear())) : '-'
      return months
    })
    const onConfirm = () => {
      formRef.value.validate((valid) => {
        if (!valid) {
          return
        }
        emit('confirm', formData)
      })
    }
    const getSubscribeOrderPayment = async () => {
      const payload = {
        shopId: shopId.value,
        orderId: get(props, 'order.id'),
        start: 0,
        limit: 100,
        // limit: tableOptions.pageLimit,
        sortBy: 'periodNumber',
        sortType: 'ASC',
      }
      const [res, err] = await GetSubscribeOrderPayment(payload)
      if (err) {
        console.error(err)
        return
      }
      paymentData.value = res
    }
    onMounted(async () => {
      await getSubscribeOrderPayment()
    })
    return {
      // periodData,
      formRef,
      formData,
      formRules,
      onConfirm,
      pausePeriod,
      periodList,
      selected,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-input, .el-select {
  @apply w-full;
}
</style>
