<template>
  <div class="external-transaction-activity">
    <PageTitle title="扣款紀錄" hideBtn @btnClick="openPaymentRecord" />
    <div class="flex mb-[20px] gap-[12px] flex-col">
      <el-checkbox-group
        v-model="search.hideOldRecord"
        @change="refresh(true)"
      >
        <el-checkbox :label="true">隱藏「當期」以前的扣款紀錄</el-checkbox>
      </el-checkbox-group>
      <el-select
        v-model="search.status"
        class="w-[400px]"
        clearable
        placeholder="扣款狀態"
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <el-option
          v-for="status in subscribeOrderPaymentStatusConfig"
          :key="status.value"
          :label="status.label"
          :value="status.value"
        />
      </el-select>
    </div>
    <SubscribePaymentRecordTable
      v-loading="loading.table"
      :tableData="tableData"
      :orderInvoiceConfig="orderInvoiceConfig"
      :orderInvoiceEnable="orderInvoiceEnable"
      @refresh="refresh(false)"
      @handleClickItem="handleClickItem"
    />
    <EditSubscribePaymentRecordDialog
      v-if="modal.paymentRecord"
      :selectItem="selectItem"
      :type="subscribePaymentRecordDialogType"
      :failRecord="failRecord"
      @refresh="refreshOrder"
      @openRecord="modal.fail = true"
      @close="modal.paymentRecord = false"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
    <SubscribePaymentRecordEditRecordDetail
      :show="modal.detail"
      @close="modal.detail = false"
      @cancel="modal.detail = false"
    />
    <SubscribePaymentFailRecord
      :show="modal.fail"
      @close="modal.fail = false"
      @cancel="modal.fail = false"
    />
    <SubscribePaymentInvoiceDialog
      v-if="modal.invoice"
      :selectItem="selectItem"
      :orderData="orderData"
      @confirm="refreshInovice"
      @close="modal.invoice = false"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import { get, isBoolean, isEmpty } from 'lodash'
import EditSubscribePaymentRecordDialog from './EditSubscribePaymentRecordDialog.vue'
import SubscribePaymentRecordTable from './SubscribePaymentRecordTable.vue'
import { subscribeOrderPaymentStatusConfig } from '@/config/subscribe'
import { GetSubscribeOrderPayment, GetSubscribeOrderPaymentCount, ManualIssueInvoice, UpdateSubscriptionPaymentInvoiceRecord } from '@/api/subscribe'
import SubscribePaymentRecordEditRecordDetail from './SubscribePaymentRecordEditRecordDetail.vue'
import SubscribePaymentFailRecord from './SubscribePaymentFailRecord.vue'
import SubscribePaymentInvoiceDialog from './SubscribePaymentInvoiceDialog.vue'

export default defineComponent({
  name: 'SubscribePaymentRecordBlock',
  components: {
    PageTitle,
    SubscribePaymentRecordTable,
    SubscribePaymentRecordEditRecordDetail,
    EditSubscribePaymentRecordDialog,
    SubscribePaymentFailRecord,
    SubscribePaymentInvoiceDialog,
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
    orderInvoiceConfig: {
      type: Object,
    },
    orderInvoiceEnable: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const route = useRoute()
    const {
      extendData,
      search,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    extendData('search', {
      // name: null,
      // content: null,
      hideOldRecord: false,
      status: null,
    })
    const failRecord = computed(() => get(props, 'orderData.failRecord'))
    const modal = reactive({
      detail: false,
      fail: false,
      invoice: false,
      paymentRecord: false,
    })
    const subscribePaymentRecordDialogType = ref('create')
    const selectItem = ref(null)
    const handleClickItem = (type, row) => {
      if (type === 'edit') {
        selectItem.value = row
        modal.paymentRecord = true
      }
      if (type === 'editInvoice') {
        selectItem.value = row
        modal.invoice = true
      }
      subscribePaymentRecordDialogType.value = type
    }
    const openPaymentRecord = () => {
      modal.detail = true
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        orderId: route.params.id,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        sortBy: 'periodNumber',
        sortType: 'ASC',
        status: search.status || undefined,
        hideOldRecord: isBoolean(search.hideOldRecord) ? search.hideOldRecord : undefined,
      }
      await Promise.allSettled([
        fetchData(GetSubscribeOrderPayment, payload),
        fetchDataCount(GetSubscribeOrderPaymentCount, payload),
      ])
    }
    const refreshOrder = async () => {
      await refresh()
      emit('refresh')
    }
    const refreshInovice = async (type, data) => {
      modal.invoice = false
      if (type === 'manual') await manualIssueInvoice(data)
      if (type === 'update') await updateSubscriptionPaymentInvoiceRecord(data)
      await refresh()
    }
    const manualIssueInvoice = async (data) => {
      const { buyerType, buyerName, buyerIdentifier, email, amount, note } = data
      const [, err] = await ManualIssueInvoice({
        shopId: shopId.value,
        orderId: route.params.id,
        id: selectItem.value.id,
        buyerType,
        buyerName: buyerType === 'B' ? buyerName : undefined,
        buyerIdentifier: buyerType === 'B' ? buyerIdentifier : undefined,
        email,
        amount,
        note: isEmpty(note) ? undefined : note,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
    }
    const updateSubscriptionPaymentInvoiceRecord = async (data) => {
      if (loading.table) return
      loading.table = true
      const { status, note } = data
      const [, err] = await UpdateSubscriptionPaymentInvoiceRecord({
        shopId: shopId.value,
        orderId: route.params.id,
        id: selectItem.value.id,
        status,
        note: isEmpty(note) ? undefined : note,
      })
      loading.table = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
    }
    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    return {
      search,
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      refresh,
      subscribePaymentRecordDialogType,
      selectItem,
      handleClickItem,
      openPaymentRecord,
      modal,
      subscribeOrderPaymentStatusConfig,
      failRecord,
      refreshOrder,
      refreshInovice,
    }
  },
})
</script>
