<template>
  <el-drawer
    title="扣款失敗紀錄"
    :visible.sync="syncShow"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    class="subscribe-edit-record-detail"
    @close="$emit('close')"
  >
    <HistoryRecord :historyData="displayData">
      <template v-slot="{record}">
        <div class="record-content">{{ record.content }}</div>
        <div class="record-content">{{ record.edit }}</div>
      </template>
    </HistoryRecord>
  </el-drawer>
</template>

<script>
import { defineComponent, computed } from 'vue'
import HistoryRecord from '@/components/HistoryRecord/HistoryRecord.vue'
import { subscribeOrderInfoEditTypeConfig } from '@/config/subscribe'
import { get } from 'lodash'
export default defineComponent({
  name: 'SubscribePaymentFailRecord',
  components: {
    HistoryRecord,
  },
  props: {
    show: Boolean,
  },
  setup (props, { emit }) {
    const displayData = computed(() => {
      // TODO: get real data
      return [
        {
          updatedAt: '2021-09-01 12:00:00',
          id: '1',
          content: '失敗紀錄...',
        },
        {
          updatedAt: '2021-09-01 12:00:00',
          id: '2',
          content: '失敗紀錄...',
        },
        {
          updatedAt: '2021-09-01 12:00:00',
          id: '3',
          content: '失敗紀錄...',
        },
      ]
    })
    const editType = (type) => {
      return get(subscribeOrderInfoEditTypeConfig, `[${type}].label`)
    }
    return { get, displayData }
  },
  computed: {
    syncShow: {
      get () {
        return this.show
      },
      set (data) {
        this.$emit('opend:show', data)
      },
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
@apply mb-0;
}

.section-block-title {
@apply text-primary-100 font-medium text-[18px] pb-[12px];
}
::v-deep .el-drawer__header {
@apply mb-0;
}
.record-content {
  @apply font-bold text-sub text-gray-100;
}
</style>
