<template>
  <el-dialog
    title="編輯發票資訊"
    :visible="true"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form v-loading="loading" ref="formRef" label-position="top" :model="formData" :rules="formRules" class="form">
      <el-form-item label="發票狀態" prop="status">
        <el-select v-model="formData.status" :disabled="statusDisabled">
          <el-option
            v-for="option in invoiceStatusConfig"
            :key="option.value"
            :value="option.value"
            :label="option.label"
            :disabled="option.disabled"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="formData.status === 'pending'" label="發票開立方式" prop="applyType">
        <el-radio-group v-model="formData.applyType" >
          <el-radio v-for="option in invoiceIssuedTypeConfig" :key="option.value" :label="option.value" @change="updateApplyType">{{ option.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="發票種類" prop="buyerType">
        <el-select v-if="editableInput" v-model="formData.buyerType">
          <el-option
            v-for="option in invoiceTypeConfig"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </el-select>
        <p v-else>{{ formData.buyerType ? invoiceType(formData.buyerType, 'label') : '-' }}</p>
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input v-if="editableInput" v-model="formData.email" placeholder="請輸入" />
        <p v-else>{{ formData.email || '-' }}</p>
      </el-form-item>
      <el-form-item v-if="showCompanyInput" label="公司抬頭" prop="buyerName">
        <el-input v-if="editableInput" v-model="formData.buyerName" placeholder="請輸入" />
        <p v-else>{{ formData.buyerName || '-' }}</p>
      </el-form-item>
      <el-form-item v-if="showCompanyInput" label="統一編號" prop="buyerIdentifier">
        <el-input v-if="editableInput" v-model="formData.buyerIdentifier" placeholder="請輸入" />
        <p v-else>{{ formData.buyerIdentifier || '-' }}</p>
      </el-form-item>
      <el-form-item v-if="formData.status !== 'pending'" label="發票號碼" prop="invoiceNo">
        <p>{{ formData.invoiceNo || '-' }}</p>
      </el-form-item>
      <el-form-item label="發票金額" prop="amount">
        <el-input v-if="editableInput" v-model="formData.amount" placeholder="請輸入" />
        <p v-else>{{ formData.amount || '-' }}</p>
      </el-form-item>
      <el-form-item label="備註" prop="note">
        <el-input v-model="formData.note" type="textarea" :autosize="{ minRows: 3, maxRows: 4}" placeholder="請輸入" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <div class>
        <el-button plain @click="$emit('close')">取消</el-button>
        <el-button type="primary" @click="onConfirm">
          確認
        </el-button>
      </div>
    </span>
    <el-dialog
      title="提示"
      :visible="modal.manual"
      width="610px"
      :close-on-click-modal="false"
      @close="modal.manual = false"
    >
      請問確定要改以手動開立發票嗎？點擊確認後即開出發票，無法再進行變更。
      <span slot="footer" class="dialog-footer">
        <div class>
          <el-button plain @click="modal.manual = false">取消</el-button>
          <el-button type="primary" @click="$emit('confirm', 'manual', formData)">
            確認
          </el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible="modal.change"
      width="610px"
      :close-on-click-modal="false"
      @close="modal.change = false"
    >
      儲存發票狀態後就不可再變動了，請問確定嗎?
      <span slot="footer" class="dialog-footer">
        <div class>
          <el-button plain @click="modal.change = false">取消</el-button>
          <el-button type="primary" @click="$emit('confirm', 'update', formData)">
            確認
          </el-button>
        </div>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted, set } from 'vue'
import { noEmptyRules, emailRules, isDigitRules, maxRules } from '@/validation'
import { invoiceTypeConfig, invoiceStatusConfig, invoiceIssuedTypeConfig } from '@/config/subscribe'
import { get } from 'lodash'
import { GetSubscriptionPaymentInvoiceRecord } from '@/api/subscribe'
import { useRoute } from 'vue-router/composables'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'SubscribePaymentInvoiceDialog',
  props: {
    selectItem: {
      type: Object,
      default: () => ({}),
    },
    orderData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const route = useRoute()
    const { shopId } = useShop()
    const loading = ref(false)
    const formRef = ref(null)
    const formData = reactive({
      status: 'pending',
      buyerType: null,
      applyType: 'auto',
      email: null,
      buyerName: null,
      buyerIdentifier: null,
      amount: null,
      note: undefined,
    })
    const formRules = computed(() => {
      let rules = {}
      if (formData.applyType === 'manual') {
        rules = {
          buyerType: [noEmptyRules()],
          email: [noEmptyRules(), emailRules()],
          buyerName: [noEmptyRules()],
          buyerIdentifier: [noEmptyRules(), isDigitRules(), maxRules(8)],
          amount: [noEmptyRules(), isDigitRules()],
        }
      } else rules = {}
      return rules
    })
    const statusDisabled = computed(() => get(props.selectItem, 'invoiceStatus') !== 'issued')
    const modal = reactive({
      manual: false,
      change: false,
    })
    const initStatus = ref('')
    const showCompanyInput = computed(() => formData.buyerType === 'B')
    const editableInput = computed(() => formData.applyType === 'manual')
    const invoiceType = (type, attr) => {
      return get(invoiceTypeConfig[type], attr)
    }
    const updateApplyType = () => {
      // clear validate
      if (formData.applyType === 'auto') formRef.value.clearValidate()
    }
    const onConfirm = () => {
      formRef.value.validate((valid) => {
        if (!valid) {
          return
        }
        if (formData.applyType === 'manual') modal.manual = true
        else if (formData.status !== initStatus.value) modal.change = true
        else {
          delete formData.status
          emit('confirm', 'update', formData)
        }
      })
    }
    const getInvoiceRecord = async (id) => {
      loading.value = true
      const [res, err] = await GetSubscriptionPaymentInvoiceRecord({
        shopId: shopId.value,
        orderId: get(route.params, 'id'),
        id,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      Object.assign(formData, res)
    }
    onMounted(async () => {
      const invoiceStatus = get(props.selectItem, 'invoiceStatus')
      if (invoiceStatus && invoiceStatus !== 'pending') {
        await getInvoiceRecord(get(props.selectItem, 'id'))
      } else if (props.orderData) {
        Object.assign(formData, get(props.orderData, 'invoiceIssueInfo'))
        set(formData, 'amount', get(props.selectItem, 'paymentAmount'))
        set(formData, 'note', get(props.selectItem, 'invoiceNote'))
      }
      initStatus.value = formData.status
    })
    return {
      formRef,
      formData,
      formRules,
      onConfirm,
      invoiceTypeConfig,
      invoiceType,
      invoiceStatusConfig,
      invoiceIssuedTypeConfig,
      showCompanyInput,
      editableInput,
      modal,
      statusDisabled,
      loading,
      updateApplyType,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-input, .el-select {
  @apply w-full;
}
</style>
