<template>
  <div class="subscribe-order-info-block">
    <!-- <PageTitle title="訂單資訊" btn-plain btn="查看訂單編輯紀錄" @btnClick="openOrderRecord" /> -->
    <PageTitle title="訂單資訊" hideBtn @btnClick="openOrderRecord" />
    <el-form
      class="form"
      label-position="left"
      label-width="150px"
    >
      <el-form-item label="訂閱方案名稱">
        <div class="flex gap-[12px]">
          <p>{{ displayData.name }}</p>
          <el-button type="text" @click="modal.subscribePlan = true">查看方案詳情</el-button>
        </div>
      </el-form-item>
      <el-form-item label="訂單狀態">
        <div class="flex gap-[12px]">
          <p>{{ selectStatus(displayData.status, 'label') || '-' }}</p>
          <p>{{ manualSettingMessage }}</p>
          <!-- <el-button v-if="displayData.status !== 'terminated' && !displayData.pauseDateRange" type="text" @click="modal.stop = true">暫停訂閱</el-button> -->
          <el-button v-if="displayData.status === 'ongoing'" type="text" @click="modal.cancel = true">取消訂閱</el-button>
        </div>
      </el-form-item>
      <!-- <el-form-item v-if="displayData.pauseDateRange" label="暫停訂閱期間">
        <div class="flex gap-[12px]">
          <p>{{ displayData.pauseDateRange }}</p>
          <el-button type="text" @click="modal.stop = true">變更暫停訂閱期間</el-button>
        </div>
      </el-form-item> -->
      <!-- <el-form-item label="自動續約">
        <p>test</p>
      </el-form-item> -->
      <!-- <el-form-item label="已續約次數">
        <p>test</p>
      </el-form-item> -->
      <!-- <el-form-item label="下次續約是否換約">
        <p>test</p>
      </el-form-item> -->
      <!-- <el-form-item label="換約的訂閱方案名稱">
        <p>test</p>
      </el-form-item> -->
      <el-form-item label="首次訂閱日期">
        <p>{{ displayData.startDate }}</p>
      </el-form-item>
      <!-- <el-form-item label="本次續約起始日">
        <p>{{ displayData.currentRenewDate }}</p>
      </el-form-item>
      <el-form-item label="下次續約起始日">
        <p>{{ displayData.nextRenewDate }}</p>
      </el-form-item> -->
      <el-form-item v-if="displayData.cancelDate" label="本次訂閱終止日">
        <div class="flex gap-[12px]">
          <p>{{ displayData.cancelDate }}</p>
          <!-- TODO: 計算是否顯示按鈕 判斷日期是不是原來的訂閱終止日 -->
          <el-button v-if="displayData.cancelDate !== displayData.currentRenewDate" type="text" @click="modal.updateEndDate = true">變更終止日</el-button>
        </div>
      </el-form-item>
      <el-form-item label="訂單編號">
        <p>{{ displayData.orderNo }}</p>
      </el-form-item>
      <el-form-item label="訂閱會員">
        <p>{{ displayData.memberName }} {{ displayData.memberPhone }}</p>
      </el-form-item>
      <el-form-item label="訂閱來源">
        <p>{{ orderOriginStatus(displayData.orderFrom, 'label') || '-' }}</p>
      </el-form-item>
      <el-form-item label="收款子帳戶">
        <p>{{ displayData.receiveAccount }}</p>
      </el-form-item>
      <el-form-item label="會員付款方式">
        <p>{{ displayData.paymentType }}</p>
      </el-form-item>
      <el-form-item label="已收款金額總和">
        <p>{{ displayData.paidAmount }}</p>
      </el-form-item>
    </el-form>
    <StopSubscribeDialog v-if="modal.stop" :order="orderData" @close="modal.stop = false" @confirm="stopSubscribe" />
    <CancelSubscribeDialog v-if="modal.cancel" :order="orderData" @close="modal.cancel = false" @confirm="cancelSubscribe" />
    <UpdateEndDateDialog v-if="modal.updateEndDate" :order="orderData" @close="modal.updateEndDate = false" @confirm="updateEndDate" />
    <SubscribeOrderInfoEditRecordDetail
      :show="modal.detail"
      @close="modal.detail = false"
      @cancel="modal.detail = false"
    />
    <SubscribePlanDialog
      v-if="modal.subscribePlan"
      :plan="orderData.snapshot"
      @close="modal.subscribePlan = false"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, computed } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import { formatDate } from '@/utils/date'
import { get } from 'lodash'
import StopSubscribeDialog from './StopSubscribeDialog.vue'
import CancelSubscribeDialog from './CancelSubscribeDialog.vue'
import UpdateEndDateDialog from './UpdateEndDateDialog.vue'
import SubscribeOrderInfoEditRecordDetail from './SubscribeOrderInfoEditRecordDetail.vue'
import SubscribePlanDialog from './SubscribePlanDialog.vue'
import { subscribeOrderStatusConfig, orderOrigins } from '@/config/subscribe'
import { CancelSuscribeOrder, PauseSuscribeOrder } from '@/api/subscribe'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'SubscribeOrderInfoBlock',
  components: {
    PageTitle,
    StopSubscribeDialog,
    CancelSubscribeDialog,
    UpdateEndDateDialog,
    SubscribeOrderInfoEditRecordDetail,
    SubscribePlanDialog,
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const modal = reactive({
      stop: false,
      cancel: false,
      updateEndDate: false,
      detail: false,
      subscribePlan: false,
    })
    const displayData = computed(() => {
      const data = get(props, 'orderData')
      const snapshot = get(data, 'snapshot')

      const id = get(data, 'id')
      const name = get(snapshot, 'name') || '-'
      const status = get(data, 'status')
      const startDate = get(data, 'startDate') ? formatDate(get(data, 'startDate'), 'YYYY/MM/DD') : '-'
      const currentRenewDate = get(data, 'currentRenewDate') ? formatDate(get(data, 'currentRenewDate'), 'YYYY/MM/DD') : '-'
      const nextRenewDate = get(data, 'nextRenewDate') ? formatDate(get(data, 'nextRenewDate'), 'YYYY/MM/DD') : '-'
      const cancelDate = get(data, 'cancelDate') ? formatDate(get(data, 'cancelDate'), 'YYYY/MM/DD') : '-'
      const pauseDate = get(data, 'pauseDate') ? formatDate(get(data, 'pauseDate'), 'YYYY/MM/DD') : null
      const resumeDate = get(data, 'resumeDate') ? formatDate(get(data, 'resumeDate'), 'YYYY/MM/DD') : null
      const orderNo = get(data, 'orderNo') || '-'
      const memberName = get(data, 'memberName') || '-'
      const memberPhone = get(data, 'memberPhone') || '-'
      const orderFrom = get(data, 'orderFrom') || '-'
      const receiveAccount = get(data, 'receiveAccount') || '-'
      const paymentType = get(data, 'paymentType') || '-'
      const totalAmount = get(data, 'totalAmount') || '-'
      const paidAmount = get(data, 'paidAmount')
      return {
        id,
        name,
        status,
        startDate,
        currentRenewDate,
        nextRenewDate,
        cancelDate,
        pauseDate,
        resumeDate,
        orderNo,
        memberName,
        memberPhone,
        orderFrom,
        receiveAccount,
        paymentType,
        pauseDateRange: pauseDate && resumeDate ? `${formatDate(pauseDate, 'YYYY/MM/DD')} - ${formatDate(resumeDate, 'YYYY/MM/DD')}` : null,
        totalAmount,
        paidAmount,
      }
    })

    const manualSettingMessage = computed(() => {
      const cancelDate = get(displayData.value, 'cancelDate')
      const currentRenewDate = get(displayData.value, 'currentRenewDate')
      // const pauseDateRange = get(displayData.value, 'pauseDateRange')

      // if ((cancelDate !== currentRenewDate) && pauseDateRange) {
      //   return '(已手動設定暫停及訂閱終止日)'
      // } else
      if (cancelDate !== currentRenewDate) {
        return '(已手動設定訂閱終止日)'
      }
      // else if (pauseDateRange) {
      //   return '(已手動設定暫停起始日)'
      // }
      return ''
    })
    const openOrderRecord = () => {
      modal.detail = true
    }
    const cancelSubscribe = async (id) => {
      const [, err] = await CancelSuscribeOrder({
        shopId: shopId.value,
        id: get(displayData, 'value.id'),
        orderPaymentId: id,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('取消成功！')
      modal.cancel = false
      emit('refresh')
    }
    const updateEndDate = async (id) => {
      const [, err] = await CancelSuscribeOrder({
        shopId: shopId.value,
        id: get(displayData, 'value.id'),
        orderPaymentId: id,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('變更成功！')
      modal.updateEndDate = false
      emit('refresh')
    }
    const stopSubscribe = async ({ startAt, endAt }) => {
      console.log('stopSubscribe')
      const [, err] = await PauseSuscribeOrder({
        shopId: shopId.value,
        id: get(displayData, 'value.id'),
        pauseOrderPaymentId: startAt,
        resumeOrderPaymentId: endAt,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('暫停成功！')
      modal.stop = false
      emit('refresh')
    }
    const selectStatus = (status, attr) => {
      return get(subscribeOrderStatusConfig[status], attr)
    }
    const orderOriginStatus = (status, attr) => {
      return get(orderOrigins[status], attr)
    }

    return {
      openOrderRecord,
      cancelSubscribe,
      stopSubscribe,
      updateEndDate,
      modal,
      displayData,
      selectStatus,
      orderOriginStatus,
      manualSettingMessage,
    }
  },
})
</script>

<style lang="postcss" scoped>
.el-form-item {
  @apply mb-[4px] text-sub;
}
.form {
  ::v-deep .el-form-item__label {
    @apply leading-[20.27px] text-gray-60 font-normal;
  }
  ::v-deep .el-form-item__content {
    @apply leading-[20.27px] text-gray-100;
  }
}

.el-button {
  @apply underline cursor-pointer py-0 m-0;
}
</style>
