<template>
  <div class="subscribe-combine-edit">
    <PageTitle class="page-title" :title="title" icon="chevron_left" hideBtn @iconClick="backToRecordList" />
    <div v-if="loading" v-loading="loading" />
    <template v-if="!loading">
      <SubscribeOrderInfoBlock
        class="card-container form-wrapper"
        :orderData="orderData"
        @refresh="refresh"
      />
      <SubscribeInvoiceBlock
        v-if="orderInvoiceEnable"
        class="card-container form-wrapper"
        :orderData="orderData"
        @refresh="refresh"
      />
      <SubscribePaymentRecordBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :orderData="orderData"
        :orderInvoiceConfig="orderInvoiceConfig"
        :orderInvoiceEnable="orderInvoiceEnable"
        @refresh="refresh"
      />
    </template>

    <PageFixedFooter @cancel="onCancel" @confirm="onSubmit" />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import store from '@/store'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import SubscribeOrderInfoBlock from './components/SubscribeOrderInfoBlock.vue'
import SubscribeInvoiceBlock from './components/SubscribeInvoiceBlock.vue'
import SubscribePaymentRecordBlock from './components/SubscribePaymentRecordBlock.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import FormsContext from './formsContext'
// import { CreateRewardActivity, FindRewardActivity, UpdateRewardActivity } from '@/api/rewardActivity'
import { FindSubscribeOrder, UpdateSubscribeOrderPayment } from '@/api/subscribe'
// import { usePermissions } from '@/use/permissions'
import { get } from 'lodash'

export default defineComponent({
  name: 'SubscribeRecordEdit',
  components: {
    PageFixedFooter,
    SubscribeOrderInfoBlock,
    SubscribeInvoiceBlock,
    SubscribePaymentRecordBlock,
    PageTitle,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const shopId = computed(() => store.getters.shop)
    const orderId = computed(() => route.params.id)
    const title = computed(() => {
      return '查看訂閱紀錄'
    })
    const orderData = ref(null)
    const loading = ref(false)
    // const { checkAction } = usePermissions()
    const orderInvoiceConfig = computed(() => {
      return get(orderData.value, 'plan.account.invoiceConfig')
    })
    const orderInvoiceEnable = computed(() => {
      return get(orderData.value, 'plan.account.invoiceConfig.enable', false)
    })
    const onCancel = () => {
      router.push({
        name: 'SubscribeRecordList',
      })
    }

    const findSubscribeOrder = async () => {
      loading.value = true
      const [res, err] = await FindSubscribeOrder({
        shopId: shopId.value,
        id: orderId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      orderData.value = res
    }

    // const createRewardActivity = async (payload) => {
    //   loading.value = true
    //   const [, err] = await CreateRewardActivity({
    //     shopId: shopId.value,
    //     postBody: {
    //       ...payload,
    //     },
    //   })
    //   if (err) throw err
    //   window.$message.success('新增成功!')
    // }

    const updateSubscribeOrderPayment = async (payload) => {
      loading.value = true
      const [, err] = await UpdateSubscribeOrderPayment({
        shopId: shopId.value,
        id: orderId.value,
        ...payload,
      })
      if (err) throw err
      window.$message.success('更新成功！')
    }
    const backToRecordList = () => {
      if (route.query.member) router.push({ name: 'MemberProfile', params: { id: route.query.member, tab: 'subscriptionRecord' } })
      else router.push({ name: 'SubscribeRecordList' })
    }
    const onSubmit = async () => {
      const allPass = await FormsContext.validateAll()
      if (allPass) {
        const data = FormsContext.composFormData()
        const postBody = {
          ...data,
        }

        try {
          // if (!orderId.value) await createRewardActivity(postBody)
          if (orderId.value) await updateSubscribeOrderPayment(postBody)
          // router.push({ name: 'externalTransactionActivity' })
        } catch (error) {
          window.$message.error(error)
          loading.value = false
        }
      }
    }
    const refresh = async () => {
      if (orderId.value) await findSubscribeOrder()
    }
    onMounted(async () => {
      await refresh()
    })

    return {
      onCancel,
      onSubmit,
      loading,
      FormsContext,
      orderData,
      title,
      refresh,
      backToRecordList,
      orderInvoiceConfig,
      orderInvoiceEnable,
    }
  },
})
</script>

<style lang="postcss" scoped>
.subscribe-combine-edit {
  @apply flex flex-col gap-[20px];
}
.page-title {
  @apply mb-0
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
