<template>
  <el-dialog
    :visible="true"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
  <div slot="title">
    <p class="el-dialog__title">編輯訂單發票資訊</p>
    <p class="text-sm text-gray-80 font-medium">編輯發票資訊僅對編輯後開立的發票生效，不影響建立的發票。</p>
  </div>
    <el-form ref="formRef" label-position="left" :model="formData" :rules="formRules" class="form">
      <el-form-item label="發票種類" prop="buyerType">
        <el-select v-model="formData.buyerType">
          <el-option
            v-for="option in invoiceTypeConfig"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input v-model="formData.email" placeholder="請輸入" />
      </el-form-item>
      <el-form-item v-if="showCompanyInput" label="公司抬頭" prop="buyerName">
        <el-input v-model="formData.buyerName" placeholder="請輸入" />
      </el-form-item>
      <el-form-item v-if="showCompanyInput" label="統一編號" prop="buyerIdentifier">
        <el-input v-model="formData.buyerIdentifier" placeholder="請輸入" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <div class>
        <el-button plain @click="$emit('close')">取消</el-button>
        <el-button type="primary" @click="onConfirm">
          確認
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import { noEmptyRules, emailRules, isDigitRules, maxRules } from '@/validation'
import { invoiceTypeConfig } from '@/config/subscribe'
import { get } from 'lodash'

export default defineComponent({
  name: 'EditOrderInvoiceDialog',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const formRef = ref(null)
    const formData = reactive({
      buyerType: 'C',
      email: null,
      buyerName: null,
      buyerIdentifier: null,
    })
    const formRules = {
      email: [noEmptyRules(), emailRules()],
      buyerName: [noEmptyRules()],
      buyerIdentifier: [noEmptyRules(), isDigitRules(), maxRules(8)],
    }
    const showCompanyInput = computed(() => formData.buyerType === 'B')

    const onConfirm = () => {
      formRef.value.validate((valid) => {
        if (!valid) {
          return
        }
        if (formData.buyerType === 'C') {
          formData.buyerName = undefined
          formData.buyerIdentifier = undefined
        }
        emit('confirm', formData)
      })
    }
    onMounted(() => {
      formData.email = get(props.order, 'invoiceIssueInfo.email')
      formData.buyerName = get(props.order, 'invoiceIssueInfo.buyerName')
      formData.buyerIdentifier = get(props.order, 'invoiceIssueInfo.buyerIdentifier')
      formData.buyerType = get(props.order, 'invoiceIssueInfo.buyerType')
    })
    return {
      formRef,
      formData,
      formRules,
      onConfirm,
      invoiceTypeConfig,
      showCompanyInput,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-input, .el-select {
  @apply w-full;
}
</style>
