<template>
  <el-dialog
    title="取消訂閱"
    :visible="true"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="formRef" label-position="left" :model="formData" :rules="formRules" class="form">
      <el-form-item label="選擇訂閱終止日期" prop="date">
        <el-select v-model="formData.date">
          <el-option
            v-for="option in periodList"
            :key="option.id"
            :value="option.id"
            :label="option.label"
            :disabled="option.disable"
          />
        </el-select>
      </el-form-item>
      <div v-if="formData.date" class="preview w-[560px] mb-[20px]">
        <GrayBlockContainer>
          <div class="flex flex-col gap-[4px] text-gray-100">
            <p class="font-medium">當前期數：<span class="font-normal">第 {{ currentPeriod || '-' }} 期 </span></p>
            <p class="font-medium">終止期數：<span class="font-normal">{{ selected.periodRange }}</span></p>
            <p class="font-medium">最後扣款日：<span class="font-normal">{{ selected.lastPaymentDate }} {{ selected.period }}</span></p>
            <p class="text-danger mt-[20px]">此會員的訂閱將於 {{ selected.date }} 取消，取消後無法回復訂閱。<br>{{ selected.date }} 以前仍可移除終止日期。</p>
          </div>
        </GrayBlockContainer>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <div class>
        <el-button plain @click="$emit('close')">取消</el-button>
        <el-button type="primary" @click="onConfirm">
          確認
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import { get, find, map, filter } from 'lodash'
import { noEmptyRules } from '@/validation'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { GetSubscribeOrderPayment } from '@/api/subscribe'
import { useTable } from '@/use/table'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'CancelSubscribeDialog',
  components: {
    GrayBlockContainer,
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { dateFormat, shopId } = useTable()
    const paymentData = ref([])
    const formRef = ref(null)
    const formData = reactive({
      date: null,
    })
    const formRules = {
      date: [noEmptyRules()],
    }
    const currentPeriod = computed(() => {
      return get(find(paymentData.value, item => dayjs().isBetween(item.startDate, item.endDate, 'day', '[)')), 'periodNumber')
    })
    const periodList = computed(() => {
      console.log(paymentData.value)
      console.log(currentPeriod.value)
      const restPeriod = filter(paymentData.value, item => item.periodNumber >= currentPeriod.value)
      console.log(restPeriod)
      return map(restPeriod, (item, index, array) => {
        let label = `${dateFormat(item.endDate, 'YYYY/MM/DD')}`
        if (index === array.length - 1) {
          label += ' (本次訂閱原定終止日期)'
        }
        return {
          id: item.id,
          label: label,
          disable: index === array.length - 1,
        }
      })
    })
    const selected = computed(() => {
      const selected = find(paymentData.value, item => item.id === formData.date) // ---- 選定的
      const selectedDate = get(selected, 'endDate')
      const lastPaymentDate = get(selected, 'expectedOrderPaymentDate')
      const selectedPeriods = filter(paymentData.value, item => item.periodNumber > selected.periodNumber) || []
      const firstPeriod = get(selectedPeriods[0], 'periodNumber')
      const lastPeriod = get(selectedPeriods[selectedPeriods.length - 1], 'periodNumber')
      const totalPeriods = selectedPeriods.length
      return {
        date: selectedDate ? dateFormat(selectedDate, 'YYYY/MM/DD') : '-',
        lastPaymentDate: lastPaymentDate ? dateFormat(lastPaymentDate, 'YYYY/MM/DD') : '-',
        period: get(selected, 'periodNumber') ? `(第 ${get(selected, 'periodNumber')} 期扣款日)` : '-',
        periodRange: `第 ${firstPeriod}  ~ ${lastPeriod} 期 (共 ${totalPeriods} 期)`,
      }
    })
    const onConfirm = () => {
      formRef.value.validate((valid) => {
        if (!valid) {
          return
        }
        emit('confirm', formData.date)
      })
    }
    const getSubscribeOrderPayment = async () => {
      const payload = {
        shopId: shopId.value,
        orderId: get(props, 'order.id'),
        start: 0,
        limit: 100,
        // limit: tableOptions.pageLimit,
        sortBy: 'periodNumber',
        sortType: 'ASC',
      }
      const [res, err] = await GetSubscribeOrderPayment(payload)
      if (err) {
        console.error(err)
        return
      }
      paymentData.value = res
    }
    onMounted(async () => {
      await getSubscribeOrderPayment()
    })
    return {
      formRef,
      formData,
      formRules,
      onConfirm,
      paymentData,
      periodList,
      dateFormat,
      currentPeriod,
      selected,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-input, .el-select {
  @apply w-full;
}
</style>
